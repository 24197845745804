<template>
  <v-container>
    <v-card class="mx-auto" max-width="800px">
      <v-card-title></v-card-title>
      <v-card-text>
        <v-form style="width: 100%" class="mt-3 text-md-center">
          <v-flex>
            <v-layout xs12>
              <v-flex xs6 class="pa-1">
                <v-text-field
                  v-model="user.company.name"
                  :label="$vuetify.lang.t('$vuetify.name')"
                  required
                  disabled
                  class="mr-2"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs6 class="pa-1">
                <v-text-field
                  v-model="user.company.email"
                  :label="$vuetify.lang.t('$vuetify.email')"
                  required
                  disabled
                >
                </v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex>
            <v-layout xs12>
              <v-flex xs6 class="pa-1">
                <v-text-field
                  v-model="user.company.address"
                  :label="$vuetify.lang.t('$vuetify.address')"
                  required
                  disabled
                  class="mr-2"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs6 class="pa-1">
                <v-text-field
                  v-model="user.company.contact"
                  :label="$vuetify.lang.t('$vuetify.contact')"
                  required
                  disabled
                >
                </v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-layout xs12>
              <v-flex xs6 class="pa-1">
                <v-text-field
                  v-model="user.company.created_at"
                  :label="$vuetify.lang.t('$vuetify.created_at')"
                  required
                  disabled
                  class="mr-2"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs6 class="pa-1">
                <v-text-field
                  v-model="user.company.email_address"
                  :label="$vuetify.lang.t('$vuetify.email_address')"
                  required
                  disabled
                >
                </v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-layout xs12>
              <v-flex xs6 class="pa-1">
                <v-text-field
                  v-model="user.company.updated_at"
                  :label="$vuetify.lang.t('$vuetify.updated_at')"
                  required
                  disabled
                  class="mr-2"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs6 class="pa-1">
                <v-text-field
                  v-model="user.company.url"
                  :label="$vuetify.lang.t('$vuetify.url')"
                  required
                  disabled
                >
                </v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex style="min-height: 120px">
            <v-layout xs12>
              <v-flex xs6 class="ma-1" @drop.prevent="(e) => drop(e, 'logo')">
                <p class="my-1">Add logo</p>
                <div
                  class="dropbox image-upload text-sm-center"
                  @click="selectImage()"
                >
                  <label for="logo-input">
                    <v-icon class="pointer" color="secondary" slot="activator"
                      >mdi-cloud-upload</v-icon
                    >
                    <h3 class="text-lightblue pointer">
                      {{ $vuetify.lang.t("$vuetify.click_here") }}
                    </h3>
                  </label>
                  <input
                    ref="logoFileInput"
                    type="file"
                    @input="pickFile('logo')"
                  />
                  <!-- <input
                    @change="setFile('logo')"
                    ref="logo_file"
                    id="logo-input"
                    type="file"
                    accept="image/png, image/jpeg"
                    name="file-input"
                  /> -->
                </div>

                <div v-if="logo_name" class="file-uploaded my-2 logo">
                  <v-icon class="pdf_icon">mdi-file-pdf-box</v-icon>
                  {{ logo_name | limitChar }}
                  <v-img
                    class="imagePreviewWrapper"
                    :src="previewLogoImage"
                    @click="selectImage"
                  >
                  </v-img>
                  <v-btn icon @click="remove('logo')"
                    ><v-icon>mdi-close-circle-outline</v-icon></v-btn
                  >
                </div>
              </v-flex>
              <v-flex xs6 class="ma-1" @drop.prevent="(e) => drop(e, 'banner')">
                <p class="my-1">Add Banner</p>
                <div
                  class="dropbox image-upload text-sm-center"
                  @click="selectBannerImage('banner')"
                >
                  <label for="banner-input">
                    <v-icon class="pointer" color="secondary" slot="activator"
                      >mdi-cloud-upload</v-icon
                    >
                    <h3 class="text-lightblue pointer">
                      {{ $vuetify.lang.t("$vuetify.click_here") }}
                    </h3>
                  </label>
                  <input
                    ref="bannerFileInput"
                    type="file"
                    @input="pickFile('banner')"
                  />
                  <!-- <input
                    @change="setFile('banner')"
                    ref="banner_file"
                    id="banner-input"
                    type="file"
                    accept="image/png, image/jpeg"
                    name="file-input"
                  /> -->
                </div>
                <div v-if="banner_name" class="file-uploaded my-2 banner">
                  <v-icon class="pdf_icon">mdi-file-pdf-box</v-icon>
                  <v-img
                    class="imagePreviewWrapper"
                    :src="previewBannerImage"
                    @click="selectImage"
                  >
                  </v-img>
                  {{ banner_name | limitChar }}
                  <v-btn icon @click="remove('banner')"
                    ><v-icon>mdi-close-circle-outline</v-icon></v-btn
                  >
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <div class="text-right mb-2 mt-5">
            <v-btn class="secondary" @click="addCompanyDetails">{{
              $vuetify.lang.t("$vuetify.save")
            }}</v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "setting-company",
  data() {
    return {
      previewLogoImage: null,
      previewBannerImage: null,
      logo_name: "",
      banner_name: "",
      users: [],
      limit: 10,
      types: "",
      companyDetailsData: {
        name: "",
        email: "",
        address: "",
        contact: "",
        emails: "",
        search: "",
      },
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    ...mapActions(["ADD_COMPANY_DETAILS"]),

    selectImage(type) {
      this.types = type;
      this.$refs.logoFileInput.click();
    },
    selectBannerImage(type) {
      console.log(type);
      this.$refs.bannerFileInput.click();
    },
    pickFile(type) {
      if (type == "logo") {
        let input = this.$refs.logoFileInput;
        let file = input.files;
        if (file && file[0]) {
          let reader = new FileReader();
          reader.onload = (e) => {
            this.previewLogoImage = e.target.result;
          };

          reader.readAsDataURL(file[0]);
          this.$emit("input", file[0]);
          this.user.logo = file[0];
          this.logo_name = file[0].name;
        }
      } else {
        let input = this.$refs.bannerFileInput;
        let file = input.files;
        if (file && file[0]) {
          let reader = new FileReader();
          reader.onload = (e) => {
            this.previewBannerImage = e.target.result;
          };

          reader.readAsDataURL(file[0]);
          this.$emit("input", file[0]);
          this.user.logo = file[0];
          this.banner_name = file[0].name;
        }
      }
    },
    drop(event, type) {
      let file = event.dataTransfer.files[0];
      if (!file) return;

      if (type == "logo") {
        this.user.logo = file;
        this.logo_name = file.name;
      }
      if (type == "banner") {
        this.user.coverLetter = file;
        this.coverLetter = file.name;
      }
    },
    setFile(type) {
      if (type == "logo") {
        this.user.logo = this.$refs.logo_file.files[0];
        this.logo_name = this.$refs.logo_file.files[0].name;
      }
      if (type == "banner") {
        this.user.logo = this.$refs.banner_file.files[0];
        this.banner_name = this.$refs.banner_file.files[0].name;
      }
    },
    remove(type) {
      if (type == "logo") {
        this.user.logo = null;
        this.logo_name = null;
      }
      if (type == "banner") {
        this.user.banner = null;
        this.banner_name = null;
      }
    },
    async addCompanyDetails() {
      await this.ADD_COMPANY_DETAILS(this.companyDetailsData);
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.imagePreviewWrapper {
  width: 250px;
  height: 250px;
  display: block;
  cursor: pointer;
  margin: 0 auto 30px;
  background-size: cover;
  background-position: center center;
}
.tableHeader {
  letter-spacing: 1px;
  font-weight: bold;
  background-color: #eaeef2;
  border: 1px solid #e6e6f1;
  color: #3dade5;
}
.tableSearch {
  background-color: #f7f7f7;
  border: 1px solid #e6e6f1;
  min-height: 70px;
}
</style>
